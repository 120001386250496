
.timeline-editor{
  background-color: aqua;
  font-family: 'Saira';
}

.timeline-editor-action{
  background-color: transparent !important;
}
.audio-split-vocal{
  background-image: url("../../../assets//images//timeline//action_audio_vox.png") !important;
}

.audio-split-drums{
  background-image: url("../../../assets//images//timeline//action_audio_drum.png") !important;
}


.audio-split-bass{
  background-image: url("../../../assets//images//timeline//action_audio_bass.png") !important;
}

.audio-split-piano{
  background-image: url("../../../assets//images//timeline//action_audio_piano.png") !important;
}

.audio-split-other{
  background-image: url("../../../assets//images//timeline//action_audio_base.png") !important;
}


.effect0{
  background-color: #0538a875;
  background-image: url("../../../assets//images//timeline//action_mediaBasicC.png");
  background-position: center;
  background-repeat: repeat-x;
  background-size: inheritf;
  width: 100%;
  height: 100%;
  font-size: 10px;
  color: #fff;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.effectAudio{
  /* background-color: rgba(73, 1, 125, 0.459); */
  background-image: url("../../../assets//images//timeline//action_audio_base.png");
}

.effectImage{
  /* background-color: rgba(73, 1, 125, 0.459); */
  background-image: url("../../../assets//images//timeline//action_mediaBasicC.png");
}




/* .effectAudio{
  background-color: #49017d75;
  background-image: url("../../../assets//images//timeline//action_audio_base.png");
} */



.isActive{
  background-color: #0c43b8b4 !important;
}

.effect0-text{
  margin-left: 4px;
  flex: 1 1 auto;
  text-align: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.timeline-editor-action-left-stretch,
.timeline-editor-action-right-stretch {
  overflow: inherit;

  &::after {
    width: 4px;
    height: 18px;
    transform: rotate(
      45deg
    ) scale(.8);
    background: #0545d0;
    border: none;
  }
}
.timeline-editor-action-left-stretch, .timeline-editor-action-right-stretch{
  width:4px !important;
  height: 50% !important;

}

.timeline-editor-action-left-stretch::after {
  left: -9px;
}

.timeline-editor-action-right-stretch::after {
  right: -9px;
}

.effect1{
  width: 25px;
  height: 28px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  & img {
    width: 100%;
    height: 100%;
  }
}

.timeline-editor-action-effect-effect0 {
  cursor: pointer;
}

.timeline-editor-action-effect-effect0 {
  cursor: pointer;
  background-color: #cd9541;
  background-image: url("../../../assets//images//timeline//soundWave.png");
  background-position: bottom;
  background-repeat: repeat-x;
  .effect0 {
    width: 100%;
    height: 100%;
    font-size: 10px;
    color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-text {
      margin-left: 4px;
      flex: 1 1 auto;
      text-align: center;
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
    }

  }


  .timeline-editor-action-left-stretch,
  .timeline-editor-action-right-stretch {
    overflow: inherit;

    &::after {
      width: 18px;
      height: 18px;
      transform: rotate(
        45deg
      ) scale(.8);
      background: #aabbcc;
      border: none;
    }
  }

  .timeline-editor-action-left-stretch::after {
    left: -9px;
  }

  .timeline-editor-action-right-stretch::after {
    right: -9px;
  }
}

