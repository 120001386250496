.content-info {
  align-items: center;
  background-color: #050c14;
  background-size: cover;
  background-position: 50% 50%;
  display: flex;
  flex-direction: column;
  min-height: 141vh;
  position: relative;
}

.content-info .intro-page {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 13px;
  position: relative;
  width: 100%;
}
.content-Info .shadow {
  background-color: #000000;
  filter: blur(200px);
  height: 348px;
  left: -73px;
  position: absolute;
  top: -160px;
  width: 2145px;
}

.sub-heading , .buttonsArea , .btn-slider {
  z-index: 100;
}
.z-index-ui {
  z-index: 10;
}

.z-index-front {
  z-index: 100;
}

.content-info .media-content {
  align-items: center;
  align-self: stretch;
  /* background-image: url(./media-content.svg); */
  background-position: 50% 50%;
  background-size: cover;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.content-info .text-content-area {
  display: flex;
  flex-direction: column;
  gap: 25px;
  /* height: 466px; */
  padding: 25px 91px;
  position: relative;
  width: 80%;
  max-width: 1200px;
  z-index: 99;
}

@media (max-width: 800px) {
  .text-content-area {
    /* height: 466px; */
    padding: 4em 0.2em  !important;
  }
  .bottom-content{
    flex-direction: column !important;
  }
  .text-wrapper{
    font-size: 3em !important;
    width: 100% !important;
    text-wrap: wrap !important;
    text-align: center !important;
  }
}


.content-info .top-content {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 100%;
}

.content-info .text-wrapper {
  color: #ffffff;
  font-family: "Aldrich", Helvetica;
  font-size: 4em;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-info .sepperating-line {
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
  
  border: 1px #ffffff8f solid;
}

.content-info .bottom-content {
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-grow: 1;
  gap: 10px;
  position: relative;
  width: 100%;
}

.content-info .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  padding: 0px 25px;
  position: relative;
}

.content-info .sub-title {
  align-self: stretch;
  color: #ffffff;
  font-family: "Aldrich", Helvetica;
  font-size: x-large;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 52px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.content-info .button-bar {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 25px;
  position: relative;
  width: 100%;
}

.content-info .button {
  border: 1px solid !important;
  border-color: #ffffff !important;
  display: flex !important;
  padding: 20px !important;
  width: 379px !important;
}

.content-info .button-2 {
  all: unset;
  align-items: center;
  border: 1px solid;
  border-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  gap: 11px;
  justify-content: center;
  padding: 20px;
  position: relative;
  width: 379px;
}

.content-info .fa-solid-download {
  height: 20px;
  position: relative;
  width: 20px;
}

.content-info .text-wrapper-2 {
  color: #ffffff;
  font-family: "Quantico", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.content-info .body-text {
  align-self: stretch;
  color: #ffffff;
  font-family: "Saira", Helvetica;
  font-size: medium;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 31.2px;
  margin-top: -1px;
  position: relative;
}
