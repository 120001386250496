.noty_body {
    font-family: sans-serif;
    line-height: 1.5em;
    color: #ff0000;
}

.noty_body code {
    background-color: rgba(0, 8, 255, 0.6);
    word-break: break-word;
    border-radius: 4px;
    font-size: 1.1em;
    padding: 2px 4px;
}

.noty-action {
    margin-bottom: 4px;
    font-weight: bold;
}


[class*="listFileEntryName"],
[class*="chonky-searchFieldContainer"],
[class*="chonky-infoContainer"],
[class*="MuiButton-label"],
[data-icon*="search"],

[class*="listFileEntryProperty"],
.chonky-infoText,
.MuiTypography-body1,
.chonky-searchFieldInputInner  {
    font-family: 'Saira';
    font-size: 12px !important;
    color: #ffffff !important;
  }
  .chonky-searchFieldContainer, .MuiOutlinedInput-marginDense{
    border: 0px !important;
  }

  .chonky-chonkyRoot {
    background-color: #00000000 !important;
    height: fit-content !important;
  }

.chonky-baseButton{
    overflow: hidden;
    border-radius: 0px !important;
}

.MuiPaper-rounded{
    border-radius: 0px !important;
    /* background-color:#0A0B10 !important; */
    /* color: white ; */

}
.chonky-text , .chonky-icon, .MuiListItemIcon-root {
    /* color: #ffffff !important; */
}

.chonky-infoText{
    font-size: 8px !important;

}

.chonky-chonkyRoot{
    height: 100% !important;
}


/* .container-rightarea {
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 1.2fr 0.8fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "PreviewArea-ra"
      "Browser-ra"; 
  }
  .Browser-ra { grid-area: Browser-ra; }
  .PreviewArea-ra { grid-area: PreviewArea-ra; } */