.buy-icon-horizontal {
  align-items: center;
  display: inline-flex;
  gap: 11px;
  justify-content: center;
  position: relative;
}

.buy-icon-horizontal .buy-icon {
  height: 30px;
  position: relative;
  width: 30px;
}

.buy-icon-horizontal .element-USD {
  color: #ffffff;
  font-family: "Quantico", Helvetica;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 26px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
