
@import url('https://fonts.googleapis.com/css2?family=Saira:ital,wght@0,100..900;1,100..900&display=swap');
:root {
  --rt-color-white: #0A0B10;
  --rt-color-dark: #0A0B10;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: 0.9;
  --rt-transition-show-delay: 0.15s;
  --rt-transition-closing-delay: 0.15s;
}
p{
font-family: 'Saira' !important;
font-size: 15px;
font-weight: 500;
line-height: 23px;
letter-spacing: 0em;
text-align: center;

}
.form-control, .display-7 ,.display-5 , button {
  font-family: 'Saira' !important;

}

.form-control, .appBlueBorder  {
  font-family: 'Saira' !important;
background-color: transparent;
border: 1px solid #183272 !important;
border-radius: 0px !important;
}

.blueborder-right  {
border-right: 1px solid #183272 !important;
border-top: 0px solid 'transparent' !important;
border-left: 0px solid 'transparent' !important;
border-bottom: 0px solid 'transparent' !important;
}

.blueborder-left  {
border-left: 1px solid #183272 !important;
border-top: 0px solid 'transparent' !important;
border-right: 0px solid 'transparent' !important;
border-bottom: 0px solid 'transparent' !important;
}
.blueborder-top  {
border-top: 1px solid #183272 !important;
border-left: 0px solid 'transparent' !important;
border-right: 0px solid 'transparent' !important;
border-bottom: 0px solid 'transparent' !important;
}

.blueborder-bottom  {
border-bottom: 1px solid #183272 !important;
border-left: 0px solid 'transparent' !important;
border-right: 0px solid 'transparent' !important;
border-top: 0px solid 'transparent' !important;
}

.btn-primary  {
  font-family: 'Saira' !important;
background-color: transparent !important;
border: 1px solid white !important;
border-radius: 0px !important;
}

.btn {
  background-color: transparent !important;
  border: 1px solid white !important;
}
h4 {
    font-size: 50px;
    color: #fff;
    /* font-weight: 700; */
    font-weight: 600;
    line-height: 110%;
    text-align-last: center;
    /* font-family: 'Poppins'; */
    /* font-family: 'Lemon/Milk light'; */
    /* letter-spacing: 4px; */

}
input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, input[type=tel]:focus, input[type=date]:focus, input[type=time]:focus, input[type=datetime-local]:focus, input[type=month]:focus, input[type=week]:focus, input[type=url]:focus, input[type=search]:focus, input[type=color]:focus,  input[type=checkbox]:focus, input[type=radio]:focus, input[type=file]:focus, input[type=button]:focus, input[type=submit]:focus, input[type=reset]:focus, input[type=image]:focus{
  outline: 1px solid rgb(0, 81, 255)  !important;     /* oranges! yey */
  color: #ffb350  !important;
  background-color: transparent !important;
}
input[type=checkbox]{
  margin: 0px !important;
  border-radius: 0px !important;
  border: 1px solid #183272 !important;
  overflow: hidden !important;
}

textarea:focus{
  outline: 1px solid rgb(0, 81, 255)  !important;     /* oranges! yey */
  color: #ffb350  !important;
  background-color: transparent !important;
}
.container {
    padding-left: 0px;
    padding-right: 0px;
    width:100% !important;
    max-width:100% !important;
}
.body div{
    background-color:#0A0B10;
}
.uai-bg{
    background-color:#0A0B10;
}

gradio-app{
    background-color:#0A0B10 !important;
    
}

.uai-bg-alternate{
    background-color:#0E111A;
}

.uai-button-base {

    /* background: #F5A214;
border-radius: 66px; */

/* display: inline-block;
color: #fff;
background-color: #F5A214;
text-align: left;
padding: 10px 40px;
cursor: pointer;
vertical-align: middle;
text-decoration: none;
transition: all 0.2s linear;
margin-bottom: 17px;
border: 2px solid #F5A214;
border-radius: 50px;
font-size: 14px;
letter-spacing: 2px;
box-sizing: border-box; */

font-family: 'Saira';
font-style: normal;
font-weight: 700;
font-size: 15px;
line-height: 22px;
text-align: center;

}
.menuButton{
  padding: 0px;
  padding-left: 0.25em;
  padding-right: 0.25em;
}
.react-select__value-container{
  position: inherit !important;
  height: 100%;
}
.uaiListItems{
  font-size: 0.75em;
}
.uaiListItemsHeader{
  font-size: 0.75em;
}
.listDeleteButton{
  height: 26px;
  width: 26px;

}
.UAIButton{
  
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  border: solid 1px #183272;
  background-color: transparent;
  color: #ededed;
}
.section-wrapper-features {
    position: relative;
    padding: 50px 0 50px;
}
.section-wrapper-faqitem {
    position: relative;
    padding: 10px 0 10px;
}

.faq-questionbox {
    text-align-last: left;
}
.faq-questiontext{
    font-weight: 700;
    text-align: inherit;
}
.faq-answertext{
    font-weight: normal;
    text-align: inherit;
}

.string-value{
  color: #ffd208;
}
.copy-icon{
  color: #ffd208 !important;
}

/* .radio-group{
    accent-color: #F5A214;

}

.radio-group input{
    background-color: blue;

} */

input[type='radio'] {
    border: 2px solid red !important;
   }


   /* completely hiding radio button */
input[type="radio"] {
    display: none;
  }
  
  /* simulate radiobutton appearance using pseudoselector */
  input[type="radio"] + label:before {
    content: "";
    /* create custom radiobutton appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 3px;
    margin-right: 5px;
    /* background-color only for content */
    background-clip: content-box;
    border: 1px solid #F5A214;
    background-color: #e7e6e700;
    border-radius: 50%;
  }
  
  /* appearance of checked radiobutton */
  input[type="radio"]:checked + label:before {
    background-color: #F5A214;
  }
  
  /* resetting default box-sizing */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    background-color: transparent;
  }
[class*="previewFile-"]{
  box-shadow: rgba(255, 255, 255, 0) 0px 0px 0px 999px inset !important;
  background-color: #0a0b1093 !important;
  border-radius: 0px !important;
  border: 1px solid #183272 !important;
  cursor: pointer !important;
  }

.lineEdit {
    border: 1px solid #F5A214;
    background: #0A0B10;
    color: white;
}

.combobox {
    border: 1px solid #F5A214;
    background: #0A0B10;
    color: white;
}

.combobox option {
    background: #0A0B10;
    color: white;
}



.vButton {
    display: inline-block;
    color: #fff;
    background-color: #F5A214;
    text-align: left;
    padding: 10px 40px;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    transition: all 0.2s linear;
    margin-bottom: 17px;
    border: 2px solid #F5A214;
    border-radius: 50px;
    font-size: 14px;
    letter-spacing: 2px;
    box-sizing: border-box;
    font-family: 'Saira';
    font-style: normal;
    font-weight: 700;
}

.vButton:hover {
    background-color: #f5a31400;
    transition: color 0.3s ease, background-color 0.3s ease;
    color: #F5A214;

}

.landing_footer-content {

    text-align-last: left;
    width: 189px;
}

.landing_footer-title {

    font-family: 'Saira';
font-size: 15px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;

}



/* unvisited link */
.landing_footer-link:link {
    color: rgb(255, 255, 255) !important;
  }
  
  /* visited link */
  .landing_footer-link:visited {
    color: rgb(255, 255, 255) !important;
  }
  
  /* mouse over link */
  .landing_footer-link:hover {
    color: #F5A214 !important;
  }
  
  /* selected link */
  .landing_footer-link:active {
    color: rgb(255, 255, 255) !important;
  }

.landing_footer-containter {

    display: flex;
    padding: 10px;

}
.landing_footer-list {

    list-style-type: none;
    list-style-image: none;
    display: list-item;


}

.landing_footer-social-icon {

    /* width: 1em;
    height: 1em; */


}

/* .landing_footer-containter-overall {

    display: flex;
    width: 100%;
    padding-left: 100px;
    padding-top: 40px;
    height: 189px;
    background: #090B10;
} */

.landing_footer-containter-social-title {

font-family: 'Saira';
font-size: 15px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
text-align: left;

}

.landing_footer-containter-social-title-Follow {

/* width: 195px; */
font-family: Saira;
font-size: 15px;
font-weight: 700;
line-height: 23px;
letter-spacing: 0em;
text-align: left;
padding-bottom: 14px;
padding-top: 0px;
}

.landing_footer-containter-social {

    /* padding-left: 157px;
    padding-right: 132px; */

}

.FollowUs-container {
    width: 82px;
    /* padding-left: 15px;
    padding-right: 132px; */

}


.video-BG {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    /* width: 100%; */
    width: 100vw;
  }
  .video-wrapperBG {
    border: 0px solid #000;
    width: 100%;
    /* height: 200px; */
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    display: grid;
    height: 266px;
  }
  .videoBGContent{
    /* position: absolute; */
    /* top: 100vh; */
    place-self: center;
    z-index: 1;
  }

  #overlay {
    position: absolute;
    /* display: none; */
    width: 100%;
    height: 100%;
    /* top: 0; */
    /* left: 0; */
    /* right: 0; */
    /* bottom: 0; */
    ;
    z-index: 1;
    cursor: pointer;
  }

  .ForceFullWidth {
    width: 100vw;
  }
  .Force100Width {
    width: 100%;
  }

  .CardInfo-Left {
    text-align: left;
    padding-left: 7px;
  }
  .CardInfo-Center {
    text-align: center;
    /* padding-left: 7px; */
  }
  .CardInfo-Container {
    border: solid 1px;
    border-radius: 2px;
    max-width: 512px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .bold-text-raw {
    font-weight: 700;
  }
  .CardInfo-Container-NotLined {
    border: solid 0px;
    border-radius: 2px;
    max-width: 512px;

    position: relative;
    /* left: 50%;
    transform: translateX(-50%); */
    text-align: center;
  }
  .CardInfo-SmallerItems-Container {
    display: flex;
    font-size: 15px;
    place-content: center;
  }
  .CardInfo-LineItem {
    display: inline;
    font-size: 15px;
    place-content: center;
  }
  .CardInfo-LineItem-Small {
    padding-right: 10px;
    margin-top: 10px;
    margin-left: 10px;
    width: 123px;
  }
  .ImageGalleryItem-Solo {
    /* max-height: 60vh; */ 
    width: auto;
    /* height: 100%; */
    /* display: block; */
    /* object-fit: scale-down; */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .ImageGalleryItem-Solo-small {
    /* max-height: 60vh; */ 
    width: inherit;
    /* height: 100%; */
    /* display: block; */
    /* object-fit: scale-down; */
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  .ImageGalleryItem {
    /* width: 100%; */
    width: auto;
/* object-fit: scale-down; */
    display: 'block';
    cursor: pointer;
  }
  .CardInfo-Container-ButtonBar-Vertical{
    display:inline;
  }
  .TopMargin10 {
    margin-top: 10px;
  }
  .NOPaadding {
    padding : 0px !important ;
  }
  .CardInfo-Small-Body {
    font-size: small ;
  }

  .CardInfo-Container-Area {
    background-color: #0A0B10;
  }
  .Remix-DownloadLink {
    color: #F5A214 !important;
    /* color: #3A57E8 !important; */
  }
  .override-CardBody-1em {
    padding-left: 1em !important;
    padding-right: 1em !important;
  }

  .button-alreadyClicked{
    
  }
  .button-clickable{
cursor: pointer;
  }

  .selectDropdown{
    border: 1px solid #565660;
    border-radius: 23px;
    border-radius: 0px;
    color: #ffffff;
    background-color: transparent !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 22px;
    padding: 7px 16px;


  }

  .txtalignlast{
    margin: auto;
    text-align-last: center;
  }

  .Divider{
    border-Top: "2em solid #28303f" !important;

  }

  .promoCode ::placeholder{
    font-size: 6px !important;
  }


  .inputlabel{
    border: none;
    min-width: 88px;
    width: 88px;
    font-size: 16px;
    padding-right: 5px;
    display: flex;
    /* display: -webkit-flex; */
    align-items: center;
    justify-content: center;
    font-weight: 700;
    line-height: 22px;
    color: var(--primary-color5);
    white-space: nowrap;
    /* text-overflow: ellipsis; */
    overflow: hidden;
    transition: all 0.3s ease;
    z-index: 10;
    position: relative;
    margin: auto;
  }
  .inputlabel-container{
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      font: inherit;
      vertical-align: baseline;
      font-family: "Saira";
      font-size: 100%;
      font-style: inherit;
      font-weight: inherit;
      width: 7em;
      text-align-last: center;
  }

  .content-container{
    display: flex;
    align-items: center;
    place-content: center;
  }



  .p-10px {
    padding: 20px 0 50px 0 !important;
}

.previewImage-row{
  width: 50%;
  height: auto;
}

.previewImage-row-portrait{
  
  width: 75%;
  margin: auto;
  margin-bottom: 41px;
  /* margin-left: auto;
  margin-right: auto; */
}


.flex-column .content-container-portrait{
  display: flex;
  flex-direction: column;
  align-items: center;

} 

.sub-heading-portrait{
  align-items: start;
  text-align: center;
  width: 100%;
  padding-right: 0px !important;
}
.heading-portrait{
  align-items: start;
  text-align: center;
  width: 100%;
  padding-right: 0px !important;
}
.content-inner{
  display: flex;
  gap: 1.3em;
  flex-direction: column;
  padding: 0 !important;
}
.content-inner-portrait{

    gap: 2em;
    display: flex;
    flex-direction: column;
}

.marginAuto{
  margin: auto;
}

.w-100{
  width: 100%;
}

.yellow-border{
  border-color: #d6e937 !important;
}

.inputform-form{
  /* padding-left:2px; */
  /* padding-right:2px; */
  /* width: 75%; */
  border-radius: 0px !important;
    border-color: #ffffff38 !important;
    background: #101010 !important;
}

.inputform-form-portrait{
  padding-left:10px;
  padding-right:10px;
  margin: auto !important;
  width: 80% !important;
}
.mb-0{
  margin-bottom: 0px;
}

.buttonsArea{
  display: flex;
  gap: 10px;
}

.buttonsArea-portrait{
  flex-direction: column;
}

.justify-content-center{
  justify-content: center;
}

.text-align-left{
  text-align: left;
}
.images-3{
  display: flex;
  gap: 5px;
}
.imgGroup-item{
  width: 33.33%;
}
.images-prompt{
  text-align: left;
  color:#2370ea !important;
  font-size: small;
}

.pointer{
  cursor: pointer;
  user-select: none;
}

.tf-button, .react-tabs__tab, .btn ,.sc-product{
  border-radius: 0 !important;
    border: 1px #454545 solid;
}

.tf-slider{
    background: transparent !important;
    /* background: linear-gradient(156.67deg, rgb(114 112 112 / 14%) 0.07%, rgb(100 97 113 / 5%) 99.07%); */
}


.tf-create{
  border-radius: 0;
    border: 1px #454545 solid;
    background: transparent !important;
    /* background: linear-gradient(156.67deg, rgb(114 112 112 / 14%) 0.07%, rgb(100 97 113 / 5%) 99.07%); */
}

.cart-text{
  text-align: center;
  padding-right: 1rem;
}



.CartContainer {  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "CartImage CartName CartQuantity CartPrice CartTax CartTotal CartCommands";
    margin-left: 1.5rem;
    width: 100%;
}

.CartImage { grid-area: CartImage; }

.CartName { grid-area: CartName; }

.CartQuantity { grid-area: CartQuantity; }

.CartPrice { grid-area: CartPrice; }

.CartTax { grid-area: CartTax; }

.CartTotal { grid-area: CartTotal; }

.CartCommands { grid-area: CartCommands; }

.fontsize-small{
  font-size: small;
}

.centeredText{

  text-align: center;
}

.cart-image-icon{
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.cart-heading-padding{
  padding: 12px 12px 12px 12px;
}


input::placeholder, input {
  font-size: small !important;
}


.shadow{
  position: absolute;
  height: 392px;
  width: 150%;
  background: black;
  filter: blur(85px);
  left: 0px;
}
.shadow-bottom{
  bottom: -166px;
}
.shadow-top{
  top: -243px;
}
.shadow-full {
  background-color: #000000c6;
    height: 110%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}


.button-uai{
  background: transparent !important;
  border: 1px solid #FFFFFF !important;
}


.heading-try{
  max-width: 627px !important;
  width: 627px !important;
  text-align: center;
}

.button-smaller{
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
}
.dashboard{
  padding: 2rem;
}

.dashboard-group{
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.borderWhite{
  border: 1px solid #7b7b7b !important;
}
.borderNone{
  border: 0px solid transparent !important;
}



.modal, .modal-dialog{
  max-width: 100% !important;
  overflow: visible !important;
}

.modal-content{
  width: 80%;
  margin: auto !important;
}

[class*="gridFileEntryName"]{
  font-family: 'Saira';
  color: #ffffff !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}





html {
  scrollbar-face-color: #183272;
  scrollbar-base-color: #183272;
  scrollbar-3dlight-color: #183272;
  scrollbar-highlight-color: #183272;
  scrollbar-track-color: #000;
  scrollbar-arrow-color: #000;
  scrollbar-shadow-color: #183272;
  scrollbar-dark-shadow-color: #183272;
}

::-webkit-scrollbar { width: 4px; height: 3px;}
::-webkit-scrollbar-button {  background-color: #183272; }
::-webkit-scrollbar-track {  background-color: #183272;}
::-webkit-scrollbar-track-piece { background-color: #000000aa;}
::-webkit-scrollbar-thumb { height: 50px; background-color: #183272; border-radius: 0px;}
::-webkit-scrollbar-corner { background-color: #183272;}
::-webkit-resizer { background-color: #183272;}



.slidecontainer {
  width: 100%; /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;  /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 10px; /* Specified height */
  background: #0b183bd1; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: .2s; /* 0.2 seconds transition on hover */
  transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 8px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #1e49b6; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px; /* Set a specific slider handle width */
  height: 25px; /* Slider handle height */
  background: #183272; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

table, th, td, .react-joyride__tooltip {
  border: 1px solid #1832727d !important;
}
.react-joyride__tooltip{
  border-radius: 0px !important;
  z-index: 10000000 !important;
}
div.accordion button {
  padding: 0.5rem  !important;
}



.chonky-infoText{
  font-size: 8px !important;

}

.p-treenode-content{
  padding-left: 1em;
  display: flex;
  
  align-items: center;
  gap: 1em;
}