@tailwind base;
@tailwind components;
@tailwind utilities;

@import url( 'https://fonts.googleapis.com/css?family=Lemon');

.Title {
    box-sizing: border-box;
    width: 100%;
    /* height: 36em;
    max-height: 36em; */
    height: 100%;
    max-height: 100%;
    display: grid;
    /* display: flex;
    justify-content: center;
    align-items: baseline;
    padding: 1em;
    margin-bottom: 2em; */
    color: #fff;
}

.Homepage_WhatIsUAI {
    width: 50%;
}
.WhatIsUAI_ImageParent {
    text-align-last: center;
}
.headerTextBody {
    width: 100%;
    position: relative;
    top: -17em;

    text-align: -webkit-center;

}
* {
    font-family: 'Poppins';
   }
.uaiContainer{
background-color: #090b10 !important;
}
.uaiContainer::before {    
       background-image: url("./images/GRAPH_LINES_WHITE.png");
    background-size: 11px;

    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 93%;
    opacity: 0.1;
    /* background-position: 50% 0; */
    /* background-size: cover; */
}

.screenReaderText { 
    border: 0; 
    clip: rect(0 0 0 0); 
    height: 1px; 
    margin: -1px; 
    overflow: hidden; 
    padding: 0; 
    position: absolute; 
    width: 1px; 
  }
.uaiButton {    
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFEAAABRCAYAAACqj0o2AAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAReSURBVHgB7Zx/UttGFMe/u2oT6ExncJlmajqtpRukNzAnKD1B6QmangBzg+YE0BOQnKDuCUJPIJOZ4v4RYuev4Imtl33gJMQjyVr5yVrk/fxjg2SxfLS/35MBj8fj8Xg8Ho/H4/F4PHkorEgcj3bwAF1o7MBlEoyj71vPUAGlJbI8vZU8MZf43fzotsA5CnT6Y3v3NwhTSmI8HIWa6Mx8+jHuGVWI1ChBABzdR4EMQR2+HF6dQBDrmhj/9+pAa32Ge45kjbSuiYEKfkUDkKyR1hJJU4iGICXSvk+k+zESF0VCZKmBpWmsKtJLnLOKyC9QEUR4BkVjrBkFdYCSk/+5SNiO2tZTnIvh69i8hMvO+5K2w7297QusmaLly8N2+uObcwq2TdtLzMBGpJeYA4u8vHzbWXaelyiAlyiAlyiAlyiAlyiAlyiAlyiAlyhAZRsQE3UdmYDWyiFZewjrpjKJGvQ3NgTfnAXwEgXwEgXwEgXwEgWobHQWYkwKfROwuY3VKMWxk1CRWyksTko0M71+ANX7od36J+14PByGAR4c8aYpHMC15jzQUN2w/c1+lkAmarcHHEhKMIn4M6gM6hUJtrkkcWCk5MpbhGUmW+onE547hzjU67R3j4uc6YxEFshSYEnUao2Th2ofojWyuEDGEYnUKyPwAzciMRNK3LQTyLggcWBb6DSi9rd9s9vRx0rYC2Rql0igPoSYJclTlKacQKZ2iVppuYz+r4I+SlFeIFO7xNn0nVi+DveN5sUyiWq5QE70f3k5OhsMRyf8fvF47RIf6q9HkMVCYjGBvDdKig4U6JDfL4ps4tq5YFpdcYH4PMssXBRZu8SpvhZbB8ejEQssILFYH6gV8VMSYcqhz0TWPzon6EKK6yIbE8UHkURPee6Z1T3cPhAFJ5ozHUKIAFjyeEiBJhyPPtbk6NGj8ySY8mooXaTCY84aq1+iwk58efUEK8JNi3JvSME+cIteXAyveh9+lyuS6E/eoHBiYDE7N0dpUwe7a+RFF20HEXW0TCSnJHf2dv+4/dsuYGpj2tShKOYfPkVmnnbZUThT5GAxp7uyxPeSmO0wZXZzWoMiJ3P/FWzjhIgO0s8oPY25e41jc43ep/OH4eJmiWsS59BxAn2aJfPmWettM4gQ9ZA5pZEQ+Kk8d0Uu4qjEWxRUn8hsuOr5XiFhh+MrZvXQRe58UFLgx9Icdtqtv9KOOB2oMqNt15js3k2voaW3vQKB5kYmk/HzrMMNW/ZVJfDNfhRFmWvyBkmsRyDTEIn1CWSsJSpSFUTWVqFegYy1xJmaPYcz1C+QsZ7iMBf/v36B2lM53BDIlOoTE5r8gkozD5bhjkCmlMSbzAMTbOc1JNaOWwKZUs35LryW1GrrZyRJaCbClX7JhlI473y3+zS/POsVeFMuNAyr/lpAINO8QFXRr5wREshsZqasoEBm8yQKC2Q2S2IFApnNkViRQMb1xHdrdEYe93Qy/rcKgR4h3gM5OWntt7SZUgAAAABJRU5ErkJggg==) !important;
    width: 28px;
    height: 44px;
    align-self: center;
    background-size: 26px;
    background-repeat: no-repeat;
    background-color: #0E0E15;
    background-position-y: 8px;
    cursor: pointer;
    /* background-size: 11px; */
/* 
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 93%;
    opacity: 0.1; */
    /* background-position: 50% 0; */
    /* background-size: cover; */
}

.uaiSaveAsButton {    
    width: 30px;
    height: 30px;
    cursor: pointer;
  
}

.uaiLinkButton {    
    align-self: center;
    height: 30px;

  
}
.uaiMediaController {    
    background-color: #0E0E15 !important;

  
}



 .aldrich {
    font-family: 'Poppins';
   } 
.fakeLink{
    cursor: pointer;
    color: #5758e0 !important;
}
.fakeLinkContainer{
    padding-top: 2em;
}
.fakeLinkDiv{
    margin: auto;
}
.downloadItem-text {
    font-size: 0.8em;
    width: 3em;
    max-width: 13em;
}
.downloadItem-textt {
    font-size: 0.8em;
}

.headerTextHeader {
    z-index: 100;
    position: relative;
    color: #5758e0 !important;

    font-weight: bold;
    font-size: 80px;
    text-align: center;
    background: linear-gradient(to left, #55b4d4, currentColor);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline;

}

.headerTextDescription {
    z-index: 100;
    position: relative;
    color: #5758e0 !important;

    /* font-weight: bold; */
    font-size: 15px;
    text-align: center;
    /* margin: 16px; */
    padding: 9px;
    width: 78%;
    background: linear-gradient(to left, #55b4d4, currentColor);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    /* transform: translate(10em); */
}

.buttoniconleft {
    display: flex;

}
.headervideo {
    width: 100%;
}

.headervideo-bg {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    min-width: 100%;
    min-height: 100%;
  }

.headervideo-content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    /* padding: 20px; */
    z-index: 1;
    transform: translate(-50%, -50%);

}

/*------------------------------------------------------------------
[Table of contents]
1. 3rd Part CSS Libraries
2. Global CSS
        2.1 Global Header Logo/Menu CSS	
        2.2 General Section CSS
        2.3 Columns CSS
        2.4 Button CSS
        2.5 Image/Text Slider CSS
3. Home Section CSS
4. Services Section CSS
5. About Section CSS
6. Clients Section CSS
7. News Section CSS
8. Portfolio Section CSS
        8.1 Single Portfolio
9. Pricing Section CSS
10. Team Section CSS
11. Skills Section CSS
12. Milestones Section CSS
13. Blog Page CSS
14. Single Post CSS
        14.1 Tipper CSS
15. Contact Section CSS
16. Footer CSS
17. Responsive CSS
-------------------------------------------------------------------*/

/* ===================================
    1. 3rd Part CSS Libraries
====================================== */
@import url("./css/clear.css");
@import url("./css/font-awesome.min.css");
@import url("./css/sm-clean.css");
@import url("./css/owl.carousel.min.css");
@import url("./css/owl.theme.default.min.css");
@import url("./css/common.css");

/* ===================================
    2. Global CSS
====================================== */

body {
    font-family: 'Saira', sans-serif;
    font-size: 18px;
    line-height: 35px;
    font-weight: 400;
    color: #b3b3b3;
    background-color:#0A0B10;
    overflow-x: hidden;
}

body a {
    font-family: 'Saira', sans-serif;
    text-decoration: none;
    color: #F5A214;
    transition: color 0.3s ease;
}
body a:hover {
    color: #F5A214;
}

body p,
body pre {
    margin-bottom: 13px;
}

.site-wrapper img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.doc-loader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    background-color: #000;
}

.doc-loader img {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.content-1170 {
    width: 1170px;
}

.content-960 {
    width: 960px;
}

.content-570 {
    width: 570px;
}

.pagination-holder {
    margin: 60px 0;
}

.section-wrapper {
    position: relative;
    padding: 100px 0 50px;
}

.section-wrapper-thin {
    position: relative;
    padding: 20px 0;
}
.section-wrapper-thinB {
    position: relative;
}

/* ===================================
    2.1 Global Header Logo/Menu CSS
====================================== */

.header-holder {
    z-index: 99 !important;
    background-color: #060606;
    width: 100% !important;
    top: 0;
    position: fixed;
    -webkit-transform: translateZ(0);
}

.sticky-wrapper {
    height: 77px !important;
}

.menu-wrapper {
    width: 98%;
    max-width: 1450px;
    min-height: 77px;
}

.header-logo {
    line-height: 0;
    font-size: 0;
    float: left;
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
}

.header-logo img {
    width: 88px;
    height: 50px;
}

.menu-holder {
    float: right;
    max-width: 85%;
}

.toggle-holder {
    display: none;
    right: 10px;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
#toggle:hover {
    cursor: pointer;
}

#toggle div {
    height: 3px;
    margin-bottom: 6px;
    background-color: #fff;
    width: 22px;
}

#toggle .second-menu-line {
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    -webkit-transform: translate3d(10px,0,0);
    transform: translate3d(10px,0,0);
}
#toggle:hover .second-menu-line {
    -webkit-transform: translate3d(0,0,0) !important;
    transform: translate3d(0,0,0) !important;
}

#toggle.on .second-menu-line {
    -webkit-transform: translate3d(-10px,0,0);
    transform: translate3d(-10px,0,0);
}

#toggle .third-menu-line {
    margin-bottom: 5px;
}

#header-main-menu {
    display: inline-block;
}

.sm-clean .has-submenu {
    padding-right: 35px !important;
}

.sm-clean li a.menu-item-link {
    cursor: pointer;
    color: #ffffff;
}

.sm-clean a span.sub-arrow {
    right: 3px;
}

.sm-clean a.highlighted {
    padding: 15px;
}
.sm-clean a,
.sm-clean a:active,
.sm-clean a:focus,
.sm-clean a:hover {
    font-size: 14px;
    font-weight: 400;
    transition: color 0.3s ease, background-color 0.3s ease;
    padding: 15px;
    letter-spacing: 0.5px;
    color: #fff;
}
.sm-clean ul a,
.sm-clean ul a:active,
.sm-clean ul a:focus,
.sm-clean ul a:hover {
    font-size: 13px;
    line-height: 15px;
    padding: 10px !important;
}

.sm-clean {
    background-color: transparent;
    padding: 15px 0;
}
body .main-menu.sm-clean .sub-menu li a:hover,
body .sm-clean a:hover,
body .sm-clean li.active a,
body .sm-clean li.current-page-ancestor > a,
body .sm-clean li.current_page_ancestor > a,
body .sm-clean li.current_page_item > a {
    color: #0357d5;
}

.sm-clean li {
    margin-right: 10px;
}
.sm-clean li:last-child {
    margin-right: 0;
}

.sm-clean ul {
    background-color: #000;
}

.sm-clean a.has-submenu {
    padding-right: 24px;
}

.children a,
.sub-menu a {
    padding-left: 15px !important;
}

.children .children,
.sub-menu .sub-menu {
    padding-top: 8px;
}
.children .children a,
.children .children a:hover,
.sub-menu .sub-menu a,
.sub-menu .sub-menu a:hover {
    padding-top: 10px !important;
}
@media (min-width: 925px) {

    .sm-clean ul a span.sub-arrow {
        margin-top: 0;
    }
    .sm-clean ul {
        padding: 8px 0;
    }
}
@media (max-width: 925px) {

    .sm-clean ul {
        background-color: #fff;
    }

}
.sm-clean > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > a,
.sm-clean > li:last-child > ul,
.sm-clean > li:last-child > ul > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > ul > li:last-child > a,
.sm-clean > li:last-child > ul > li:last-child > ul,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > a,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > *:not(ul) a,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > a,
.sm-clean > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul > li:last-child > ul {
    border-radius: 5px;
}

/* ===================================
    2.2 General Section CSS
====================================== */

.section {
    position: relative;
}

.page-title-holder {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #0357d5;
    width: 400px;
    display: inline-block;
    z-index: 98;
    height: 130px;
}
.page-title-holder:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 130px 60px 0 0;
    border-color: #0357d5 transparent transparent transparent;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(100%);
}

.section h3.entry-title {
    font-size: 16px;
    line-height: 149%;
    font-weight: 400;
    word-break: break-word;
    text-align: center;
    color: #fff;
    letter-spacing: 2px;
    margin-top: 32px;
}

.page .page-content {
    background-color: #fff;
    padding: 75px;
}

.no-padding .section-wrapper {
    padding: 0 !important;
    width: 100%;
    max-width: 100%;
}

[data-jarallax-element] {
    z-index: 30 !important;
}

.medium-text {
    color: #000;
    font-size: 60px;
    line-height: 120%;
    font-weight: 700;
    padding-bottom: 35px;
}

.title-description-up {
    font-size: 16px;
    margin-bottom: 0;
    letter-spacing: 2px;
}

.info-text {
    font-size: 22px;
    line-height: 190%;
    color: #000;
}

.info-code-title {
    font-size: 16px;
    color: #000;
    letter-spacing: 2px;
    margin-bottom: 0;
    line-height: 120%;
}

.info-code-content,
.info-code-content a {
    color: #b3b3b3;
    margin-bottom: 30px;
}

.tp-bullet-title {
    display: none !important;
}

.isotope .isotope-item.no-transition,
.isotope.no-transition,
.isotope.no-transition .isotope-item {
    transition-duration: 0s;
}

.social {
    display: inline-block;
    font-size: 25px;
    margin-right: 20px;
}

body .social a {
    color: #000 !important;
    transition: all 0.3s ease;
}
body .social a:hover {
    opacity: 0.5;
}

/* ===================================
    2.3 Columns CSS
====================================== */

.one_fourth,
.one_half,
.one_third,
.three_fourth,
.two_third {
    margin-right: 8%;
    float: left;
    position: relative;
    margin-bottom: 30px;
}

.last {
    margin-right: 0 !important;
    clear: right;
}

.one {
    display: block;
    clear: both;
    margin-bottom: 30px;
}

.one_half {
    width: 46%;
}

.one_third {
    width: 28%;
}

.two_third {
    width: 64%;
}

.one_fourth {
    width: 19%;
}

.three_fourth {
    width: 73%;
}

.one_half.margin-0 {
    width: 50%;
}

.one_third.margin-0 {
    width: calc(100% / 3);
}

.two_third.margin-0 {
    width: calc(100% / 3 * 2);
}

.one_fourth.margin-0 {
    width: 25%;
}

.three_fourth.margin-0 {
    width: 75%;
}

/* ===================================
    2.4 Button CSS
====================================== */

body a.button {
    display: inline-block;
    color: #fff;
    background-color: #F5A214;
    text-align: left;
    padding: 10px 40px;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    transition: all 0.2s linear;
    margin-bottom: 17px;
    border: 2px solid #F5A214;
    border-radius: 50px;
    font-size: 14px;
    letter-spacing: 2px;
    box-sizing: border-box;
}
a.button:hover {
    background-color: transparent;
    color: #F5A214;
}

a.button-dot {
    color: #fff !important;
    font-size: 16px;
    position: relative;
}
a.button-dot:hover {
    color: #fff;
}

a.button-dot span {
    transition: 0.3s;
    display: inline-block;
    margin-left: 50px;
    letter-spacing: 2px;
}
a.button-dot:hover span {
    transform: translateX(-8px);
}
a.button-dot:before {
    content: "";
    width: 30px;
    height: 30px;
    border: 2px solid #fff;
    background: transparent;
    border-radius: 90%;
    top: -7px;
    position: absolute;
    transition: 0.3s;
}
a.button-dot:hover:before {
    width: calc(100% + 30px);
    height: 51px;
    border-radius: 50px;
    top: -18px;
}

/* ===================================
    2.5 Image/Text Slider CSS
====================================== */

.image-slider-wrapper {
    margin-bottom: 20px;
}

.single-post-header-content .image-slider-wrapper {
    margin-bottom: 0;
}

.owl-carousel .owl-stage-outer {
    height: auto !important;
}

.owl-theme .owl-dots {
    padding-top: 25px;
    line-height: 0;
}

.owl-theme .owl-dots .owl-dot span {
    margin: 0;
    background-color: rgba(255, 255, 255, 0.5);
    transition: all 0.2s ease;
    width: 17px;
    height: 17px;
    border-radius: 90%;
}

.owl-theme .owl-dots .owl-dot {
    border-radius: 90%;
    transition: all 0.3s ease;
    border: 2px solid #000;
    margin: 3px;
}
.owl-theme .owl-dots .owl-dot:hover span {
    background-color: #fff;
}

.owl-theme .owl-dots .owl-dot.active {
    background-color: #000;
}

.owl-theme .owl-dots .owl-dot.active span {
    background-color: transparent;
}

.text-slider-wrapper {
    font-size: 24px;
    line-height: 35px;
    background-color: #000;
    padding: 130px;
}

.text-slider-header-quotes {
    margin-bottom: 60px;
    background-image: url("./images/quotes_image.png");
    background-repeat: no-repeat;
    width: 87px;
    height: 74px;
    background-size: 87px 74px;
}

.text-slider-wrapper .text-slide {
    text-align: left;
    font-size: 28px;
    line-height: 170%;
    overflow: hidden;
}
.text-slider-wrapper .text-slide:after {
    content: "";
    display: table;
    clear: both;
}

.text-slider-wrapper .text-slide-name {
    font-size: 18px;
    line-height: 100%;
    margin: 0 12px 0 0;
    display: inline-block;
    vertical-align: middle;
}

.text-slider-wrapper .text-slide-position {
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 4px;
    color: #fff;
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}

.text-slider-wrapper img.text-slide-img {
    width: 70px !important;
    height: 70px;
    border-radius: 90%;
    display: inline-block;
    margin-right: 25px;
    vertical-align: middle;
}

.text-slide-content {
    margin-bottom: 60px;
}

.text-slider-wrapper .owl-nav {
    position: absolute;
    bottom: 10px;
    right: 0;
}

.text-slider-wrapper .owl-theme .owl-prev {
    background-image: url('images/nav_left.png') !important;
    background-color: transparent !important;
    font-size: 0;
    width: 30px;
    padding: 0;
    height: 30px;
    background-repeat: no-repeat !important;
    background-size: 30px !important;
    transition: 0.3s;
    opacity: 0.7;
    margin-right: 10px;
}
.text-slider-wrapper .owl-theme .owl-prev:hover {
    opacity: 1;
}

.text-slider-wrapper .owl-theme .owl-next {
    background-image: url('images/nav_right.png') !important;
    background-color: transparent !important;
    font-size: 0;
    width: 30px;
    padding: 0;
    height: 30px;
    background-repeat: no-repeat !important;
    background-size: 30px !important;
    transition: 0.3s;
    opacity: 0.7;
}
.text-slider-wrapper .owl-theme .owl-next:hover {
    opacity: 1;
}
@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
}
@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

/* ===================================
    3. Home Section CSS
====================================== */

#home {
    background-image: url("images/hero_image_01.jpg");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: calc(100vh - 77px);
    display: flex;
}

#home .section-wrapper {
    margin: auto;
    padding: 0;
}

h1.big-text {
    color: #fff;
    font-size: 80px;
    line-height: 120%;
    margin-bottom: 40px;
}

/* ===================================
    4. Services Section CSS
====================================== */

#services {
    /* background-image: url("images/on_black_left.png"); */
    /* background-color: #090B10; */
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: auto;
}

.features-holder {
    width: 468px;
}
.service-holder {
    margin: 25px 0;
}
.service-holder:after {
    content: "";
    display: block;
    clear: both;
    text-align-last: center;
}

.communityspotlight-centered-txt h4 {
    font-size: 46px;
    color: #fff;
    font-weight: 700;
    line-height: 110%;
    text-align-last: center;
    padding-top:58px;

}
.boundless-centered-txt h4 {
    font-size: 46px;
    line-height: 41px;

}

.boundless-centered-bodytext {
    width: 80%;
    max-width: 32em;
    font-family: Poppins;
font-size: 15px;
font-weight: normal;
line-height: 23px;
letter-spacing: 0em;
text-align: center;

}

.boundless-centered-bodytext-container {
    text-align: -webkit-center;
    overflow: hidden;

}

.service-txt h4 {
    font-size: 46px;
    color: #fff;
    font-weight: 700;
    line-height: 110%;
    text-align-last: center;

}
.service-txt {
    width: 208px;
    margin: auto;

}
.features-bodytext {
    text-align-last: left;


}

.service-num {
    font-size: 264px;
    font-weight: 700;
    color: #000000;
    text-shadow: -1px 0 #b3b3b3, 0 1px #b3b3b3, 1px 0 #b3b3b3, 0 -1px #b3b3b3;
    line-height: 100%;
    height: 165px;
    overflow: hidden;
    margin-bottom: -10px;
    -webkit-transform: translateZ(0);
    text-align-last: center;

}


/* ===================================
    5. About Section CSS
====================================== */

#about {
    background-image: url("images/on_white_right.png");
    background-color: #0F111A;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto;
}

/* ===================================
    6. Clients Section CSS
====================================== */

#clients {
    background-image: url("images/quote_image_01.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

/* ===================================
    7. News Section CSS
====================================== */

#news {
    background-image: url("images/on_white_left.png");
    background-color: #090B10;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto;
}

.blog-holder-scode {
    margin-top: 0;
    width: 1370px;
    transform: translateX(-100px);
    text-align: center;
}
.blog-holder-scode article:nth-of-type(3n) {
    margin-right: 0;
}

.blog-item-holder-scode {
    margin: 30px 0;
    width: 27%;
    display: inline-block;
    margin-right: 8%;
    vertical-align: text-top;
    text-align: left;
}

.blog-item-holder-scode .excerpt {
    margin-top: 20px;
}

.blog-item-holder-scode h4 {
    padding: 0;
    margin: 35px 0;
}

.blog-item-holder-scode h4 a {
    color: #000 !important;
    font-size: 34px;
    line-height: 110%;
    font-weight: 700;
    transition: 0.3s;
    letter-spacing: -1px;
}
.blog-item-holder-scode h4 a:hover {
    opacity: 0.8;
}

.blog-item-holder-scode .post-thumbnail {
    overflow: hidden;
}

.latest-posts-background-featured-image-holder {
    width: 100%;
    height: 600px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    transition: 0.3s;
}
.latest-posts-background-featured-image-holder:hover {
    opacity: 0.8;
    transform: scale(1.1);
}

/* ===================================
    8. Portfolio Section CSS
====================================== */

.grid {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    max-width: 100%;
    font-size: 0;
    line-height: 0;
}

.grid-sizer {
    width: 25%;
}

.grid-item {
    float: left;
    font-size: 0;
    line-height: 0;
    width: 100%;
    overflow: hidden;
}

.grid-item.p_one_fourth {
    width: 25%;
}

.grid-item.p_one_half {
    width: 50%;
}

.grid-item.p_three_fourth {
    width: 75%;
}

.grid-item.p_one {
    width: 100%;
}

.grid-item img {
    width: 100% !important;
    height: auto;
    display: block;
}

.grid-item .entry-holder {
    text-align: center;
    background-color: #fff;
    padding: 50px 20px;
}
.grid-item a.item-link:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    left: 0;
    top: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.portfolio-text-holder {
    color: #fff;
    position: absolute;
    bottom: 50px;
    left: 50px;
    display: inline-block;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s ease;
}

.portfolio-title {
    font-size: 30px;
    line-height: 170%;
    margin-bottom: 0;
    font-weight: 700;
    color: #b3b3b3;
}

.portfolio-desc {
    font-size: 14px;
    line-height: 22px;
    color: #b3b3b3;
    letter-spacing: 2px;
    margin-bottom: 0;
}
.grid-item a.item-link:hover .portfolio-text-holder,
.grid-item a.item-link:hover:after {
    opacity: 1;
}

.portfolio-content h1 {
    font-size: 44px;
    color: #000;
    line-height: 130%;
}

.portfolio-load-content-holder {
    opacity: 0;
    transform: translateY(70px);
    transition: all 0.5s ease;
    width: 1170px;
    max-width: 75%;
    margin: 0 auto;
}

.portfolio-load-content-holder.show {
    transform: translateY(0);
    opacity: 1;
}

.portfolio-load-content-holder.show.viceversa {
    opacity: 0;
    transform: translateY(70px);
}

.section.no-padding .portfolio-content-wrapper {
    padding: 195px 0 75px;
}

.portfolio-content-wrapper {
    display: none;
}

.portfolio-content-wrapper.show {
    display: block;
}

#portfolio-grid {
    transition: all 0.5s ease;
    opacity: 1;
    transform: translateY(0px);
}

#portfolio-grid.hide {
    opacity: 0;
    transform: translateY(70px);
}

.close-icon {
    background-image: url("images/close-left-arrow.png");
    background-repeat: no-repeat;
    width: 70px;
    height: 70px;
    display: inline-block;
    margin-bottom: 20px;
    background-size: 40px;
    background-position: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    left: -100px;
    top: 195px;
}
.close-icon:hover {
    transform: translateX(-5px);
}

/* ===================================
    8.1 Single Portfolio
====================================== */

.portfolio-item-wrapper {
    margin-top: 100px;
    margin-bottom: 50px;
}

/* ===================================
    9. Pricing Section CSS
====================================== */

#pricing {
    background-image: url("images/on_white_right.png");
    background-color: #090B10;
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: auto;
}

.pricing-table {
    padding-bottom: 20px;
    background-color: #090B10;
    margin-top: 30px;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
    border: 2px solid #000;
    position: relative;
    text-align: left;
}

.pricing-wrapper {
    padding: 0 15%;
}

.pricing-table-title {
    color: #000;
    font-size: 21px;
    font-weight: 500;
    padding: 40px 0;
    margin-bottom: 25px;
}

.pricing-num {
    font-size: 200px;
    font-weight: 700;
    color: #ffffff;
    text-shadow: -1px 0 #b3b3b3, 0 1px #b3b3b3, 1px 0 #b3b3b3, 0 -1px #b3b3b3;
    line-height: 100%;
    margin-bottom: -10px;
    position: absolute;
    top: -50px;
    right: -9px;
    z-index: 1;
}
.pricing-num:before {
    content: "";
    width: 150px;
    background-color: white;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: -1;
}

.pricing-table-price {
    font-size: 100px;
    line-height: 120%;
    color: #0357d5;
    font-weight: 700;
}

.pricing-table-desc {
    font-size: 16px;
    letter-spacing: 2px;
}

.pricing-table ul {
    padding: 0;
    list-style: none;
    margin-top: 25px;
}

.pricing-table ul li {
    padding: 10px 0;
    color: #000;
}

.pricing-list span.fa {
    color: #0357d5;
    margin-right: 25px;
}

.pricing-list.included-no,
.pricing-list.included-no span.fa {
    color: gray;
}
.pricing-list.included-no span.fa:before {
    content: "\f00d";
}

.pricing-table-content-holder {
    margin-bottom: 40px;
}

.pricing-button {
    text-align: center;
}

/* ===================================
    10. Team Section CSS
====================================== */

#team {
    background-image: url("images/on_black_left.png");
    background-color: #000000;
    background-position: left center;
    background-repeat: no-repeat;
    background-size: auto;
}

.member {
    text-align: center;
    margin: 70px 0;
}

.member img {
    display: inline-block;
    vertical-align: middle;
    max-width: 700px;
}

.member-content {
    margin-bottom: 35px;
}

.member-info {
    width: 380px;
    background-color: #fff;
    padding: 65px 110px;
    display: inline-block;
    vertical-align: middle;
    margin-left: -130px;
    text-align: left;
    position: relative;
    z-index: 31;
}

.member-right .member-info {
    margin-left: auto;
    margin-right: -130px;
}

.member-postition {
    margin: 0;
    font-size: 16px;
    letter-spacing: 2px;
}

.member-name {
    font-size: 48px;
    font-weight: 700;
    color: #000;
    line-height: 150%;
    margin-bottom: 35px;
    margin-top: -5px;
    padding: 0;
}

.member-social-holder {
    background: #0357d5;
    padding: 25px 50px;
    position: absolute;
    bottom: -35px;
    left: -45px;
}

.member-right .member-social-holder {
    left: auto;
    right: -45px;
}

.member-social-holder .social a {
    color: #fff !important;
}

/* ===================================
    11. Skills Section CSS
====================================== */

.skills-holder {
    position: relative;
    margin: 40px 0;
}

.skill-holder {
    margin-bottom: 30px;
}
.skill-holder:after {
    clear: both;
    display: block;
    content: "";
}

.skill-percent {
    font-size: 48px;
    line-height: 100%;
    font-weight: 100;
    width: 100px;
    display: inline-block;
    vertical-align: text-top;
}

.skill {
    width: 100%;
    height: 3px;
    background-color: rgba(202, 202, 202, 0.3);
    margin-bottom: 30px;
}

.skill-text {
    display: inline-block;
    width: calc(100% - 145px);
    margin-right: 0;
    float: right;
    vertical-align: text-top;
}

.skill-fill {
    width: 100%;
    height: 10px;
    background-color: #000;
}

.skill-text span {
    font-size: 22px;
    line-height: 35px;
}

/* ===================================
    12. Milestones Section CSS
====================================== */

#millstones {
    background-image: url("images/on_black_left.png");
    background-color: #000000;
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: auto;
}

ul.milestones-holder {
    padding: 0;
    list-style: none;
    margin: 0;
}

li.milestone {
    width: 45%;
    display: inline-block;
    margin: 30px 9% 30px 0;
}
li.milestone:nth-child(2n) {
    margin-right: 0;
}

li.milestone > div {
    display: inline-block;
    vertical-align: text-top;
}

li.milestone h5 {
    color: #fff;
    max-width: 240px;
    padding-top: 0;
    line-height: 115%;
}

li.milestone .milestone-info-left {
    margin-right: 35px;
    text-align: right;
    width: 30%;
}

li.milestone .milestone-info-right {
    width: 62%;
}

p.milestone-num {
    font-size: 80px;
    line-height: 100%;
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    color: #000000;
    text-shadow: -1px 0 #b3b3b3, 0 1px #b3b3b3, 1px 0 #b3b3b3, 0 -1px #b3b3b3;
}

/* ===================================
    13. Blog Page CSS
====================================== */

body.blog {
    background-color: #fff;
}

.blog .site-content {
    background-color: #fff;
}

.blog h1.entry-title {
    text-align: center;
    display: table;
    margin: 50px auto;
    font-family: 'Saira', sans-serif;
    font-weight: 600;
    color: #fff;
    background-color: #f1576b;
    padding: 15px 25px;
    border-radius: 50px;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
}

.blog-holder {
    width: 1170px;
    max-width: 95%;
}

.blog-item-holder {
    margin: 120px 0;
    text-align: center;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.blog-item-wrapper {
    display: inline-block;
}

.blog-item-holder .excerpt {
    width: 700px;
    margin: 0 auto;
    max-width: 95%;
    margin-bottom: 30px;
    margin-top: 25px;
}

.blog-item-holder.has-post-thumbnail {
    width: 1170px;
    max-width: 100%;
}

.blog-item-holder.has-post-thumbnail .excerpt {
    width: 100%;
    max-width: 100%;
    margin-bottom: 60px;
}

.blog-item-holder .post-thumbnail {
    max-width: 590px;
    overflow: hidden;
    line-height: 0;
}

.post-thumbnail-image {
    transition: 0.3s;
}
.post-thumbnail-image:hover {
    opacity: 0.8;
}
.blog-item-holder.has-post-thumbnail:nth-of-type(2n+1) .post-thumbnail {
    float: left;
    text-align: right;
}
.blog-item-holder.has-post-thumbnail:nth-of-type(2n+1) .entry-holder {
    float: left;
    position: relative;
    z-index: 31;
}
.blog-item-holder.has-post-thumbnail:nth-of-type(2n) .post-thumbnail {
    float: right;
    text-align: left;
    margin-right: 0;
}
.blog-item-holder.has-post-thumbnail:nth-of-type(2n) .entry-holder {
    float: right;
    position: relative;
    z-index: 31;
}

.blog-item-holder.has-post-thumbnail .entry-holder {
    width: 430px;
    text-align: left;
    padding: 0 75px;
    background: #fff;
}

.blog-item-holder.has-post-thumbnail.is-smaller .entry-holder {
    margin-top: 40px;
}

.blog-item-holder h2.entry-title {
    display: block;
    font-weight: 700;
    font-size: 42px;
    line-height: 46px;
    padding-top: 0;
    word-break: break-word;
    letter-spacing: -2px;
}

.blog-item-holder h2.entry-title a {
    color: #000 !important;
    transition: 0.2s;
}
.blog-item-holder h2.entry-title a:hover {
    opacity: 0.8;
}

.blog-item-holder .cat-links ul {
    list-style: none;
    padding: 0;
    display: inline-block;
}

.blog-item-holder .cat-links ul li {
    display: inline-block;
}
.blog-item-holder .cat-links ul li a:after {
    content: ",";
    padding-right: 5px;
}
.blog-item-holder .cat-links ul li:last-child a:after {
    display: none;
}

.blog-item-holder .cat-links ul a {
    color: #000000 !important;
    transition: 0.2s;
}
.blog-item-holder .cat-links ul a:hover {
    opacity: 0.8;
}

.blog-item-holder .cat-links,
.blog-item-holder .entry-date.published {
    display: inline-block;
    font-size: 12px;
    margin-top: -10px;
    letter-spacing: 2px;
    text-transform: uppercase;
}
.blog-item-holder .cat-links:after {
    content: "\2022";
    display: inline-block;
    margin: 0 7px 0 10px;
}

.blog-item-holder a.item-button {
    display: inline-block;
    color: #fff;
    background-color: #0357d5;
    text-align: left;
    padding: 10px 40px;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    transition: all 0.2s linear;
    margin-bottom: 17px;
    border: 2px solid #0357d5;
    border-radius: 50px;
    font-size: 14px;
    letter-spacing: 2px;
    box-sizing: border-box;
    margin-bottom: 0;
}
.blog-item-holder a.item-button:hover {
    background-color: #fff;
    color: #0357d5;
}

/* ===================================
    14. Single Post CSS
====================================== */

.single .site-content {
    background-color: #fff;
}

.single-post-header-content {
    margin-bottom: 55px;
}

.single .post-info-wrapper {
    margin-bottom: 30px;
    font-size: 15px;
    color: #939393;
    width: 150px;
    margin-right: 200px;
    float: left;
    padding-top: 7px;
}

.single .post-info-wrapper a {
    color: #939393 !important;
}

.single .sticky-spacer {
    position: static !important;
    margin-bottom: 0 !important;
    max-width: 150px;
}

.single .entry-info {
    position: relative;
}

.single .entry-info > div {
    margin-bottom: 20px;
}
.single .entry-info div:last-child {
    margin-bottom: 0;
}

.single .entry-info .cat-links {
    margin-top: 5px;
}
.single .entry-info:after {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    right: -20px;
    top: -5px;
    background-color: #0357d5;
}

.single .post-info-wrapper .text-holder {
    font-size: 10px;
    color: #000;
    letter-spacing: 2px;
    margin-bottom: 0;
    line-height: 15px;
}

.single article {
    padding-top: 75px;
}

.single h1.entry-title {
    font-weight: 700;
    font-size: 48px;
    line-height: 115%;
    margin-bottom: 10px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    text-align: center;
    width: 720px;
    margin: 0 auto 30px;
    max-width: 95%;
    color: #000;
    padding-top: 0;
}
.single .entry-info div:last-of-type:after {
    display: none;
}

.single .entry-info ul {
    padding: 0;
    margin: 0;
    list-style: none;
    line-height: 150%;
}

.single .entry-info ul li {
    display: inline-block;
}
.single .entry-info .cat-links li:after {
    content: ",";
    padding-right: 5px;
}
.single .entry-info .cat-links ul li:last-child:after {
    display: none;
}

.single-post-featured-image {
    text-align: center;
    padding-bottom: 40px;
    width: 1300px;
    margin-left: auto;
    margin-right: auto;
    max-width: 95%;
}

.single .entry-content {
    font-size: 15px;
    line-height: 30px;
    color: #000;
    width: 680px;
    float: left;
}

.single .nav-links {
    transform: translateY(70px);
    margin-top: 35px;
    margin-bottom: 50px;
}

.single .nav-links > a {
    background-color: #0357d5;
    padding: 26px 30px 24px;
    margin: 0;
    display: inline;
    color: #fff !important;
    transition: 0.3s;
}
.single .nav-links > a:hover {
    background-color: #73f473;
}

/* ===================================
   14.1 Tipper CSS
====================================== */

.tipper {
    width: 100%;
    min-height: 1px;
    position: absolute;
    top: -999px;
    left: -999px;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.15s linear;
    max-width: 500px;
}

.tipper-visible {
    opacity: 1;
}

.tipper-content {
    background: #111;
    border-radius: 3px;
    color: #fff;
    display: block;
    float: left;
    font-size: 22px;
    margin: 0;
    padding: 10px 15px;
    position: relative;
    line-height: 28px;
    font-weight: 700;
    text-align: center;
}

.tipper-caret {
    width: 0;
    height: 0;
    content: '';
    display: block;
    margin: 0;
    position: absolute;
}

.tipper-left .tipper-content {
    box-shadow: -1px 0 5px rgba(0, 0, 0, 0.35);
}

/* ===================================
    15. Contact Section CSS
====================================== */

.contact-form {
    max-width: 100%;
    margin: 0 auto;
    font-size: 22px;
    color: #000;
}

.contact-form p {
    margin-bottom: 0 !important;
}

.contact-form input[type=email],
.contact-form input[type=text],
.contact-form textarea {
    font-family: 'Saira', sans-serif;
    font-weight: 500;
    border: 0;
    font-size: 15px;
    padding: 5px 0;
    width: 100%;
    line-height: 25px;
    color: #000;
    margin-bottom: 35px;
    text-indent: 10px;
    background-color: transparent;
    border-bottom: 3px solid;
}

.contact-form textarea {
    height: 150px;
}

.contact-form input[type=email]::placeholder,
.contact-form input[type=text]::placeholder,
.contact-form textarea::placeholder {
    font-family: 'Saira', sans-serif;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
    opacity: 1;
}
.contact-form input[type=email]:ms-input-placeholder,
.contact-form input[type=text]:ms-input-placeholder,
.contact-form textarea:ms-input-placeholder {
    font-family: 'Saira', sans-serif;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
}

.contact-form input[type=email]::ms-input-placeholder,
.contact-form input[type=text]::ms-input-placeholder,
.contact-form textarea::ms-input-placeholder {
    font-family: 'Saira', sans-serif;
    color: #000;
    font-weight: 500;
    font-size: 15px;
    line-height: 25px;
}

.contact-form input[name="your-name"] {
    margin-top: 0;
}

.contact-submit-holder {
    position: relative;
    margin-bottom: 25px;
}

.contact-form input[type=submit] {
    color: #fff;
    background-color: #0357d5;
    text-align: center;
    padding: 10px 40px;
    cursor: pointer;
    vertical-align: middle;
    text-decoration: none;
    transition: all 0.2s linear;
    margin-bottom: 17px;
    display: block;
    width: 100%;
    line-height: 35px;
    border: 2px solid #0357d5;
    border-radius: 50px;
    font-size: 14px;
    letter-spacing: 2px;
    box-sizing: border-box;
}
.contact-form input[type=submit]:hover {
    background-color: #fff;
    color: #0357d5;
}

/* ===================================
    16. Footer CSS
====================================== */

footer a {
    color: #fff;
}

footer ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
}

.footer {
    font-size: 16px;
    line-height: 36px;
    padding-top: 75px;
    padding-bottom: 60px;
    clear: both;
    color: #fff;
    text-align: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center top;
    background-color: #111;
}

.footer-content {
    width: 900px;
}

.footer-logo img {
    width: 88px;
    height: 50px;
    margin-bottom: 30px;
}

.footer-logo-divider {
    position: relative;
    height: 60px;
    margin-bottom: 30px;
}
.footer-logo-divider:before {
    content: "";
    width: 2px;
    height: 60px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: calc(50% - 1px);
}

.footer-mail {
    font-size: 46px;
    margin-bottom: 35px;
    line-height: 100%;
    font-weight: 700;
}

.footer-social-divider {
    position: relative;
    height: 62px;
    margin-bottom: 35px;
}
footer .footer-social-divider:after {
    content: "";
    position: absolute;
    width: 300px;
    height: 2px;
    background-color: white;
    left: calc(50% - 150px);
    bottom: 0;
}
footer .footer-social-divider:before {
    content: "";
    position: absolute;
    width: 2px;
    height: 60px;
    background-color: white;
    left: calc(50% - 1px);
    top: 0;
}

.social-holder {
    margin-bottom: 35px;
}

.social-holder a {
    font-size: 28px;
    margin-right: 25px;
}
.social-holder a:last-of-type {
    margin: 0;
}

/* ===================================
    17. Responsive CSS
====================================== */
@media screen and (max-width: 1600px) {

    .content-1170,
    .content-570,
    .content-960 {
        max-width: 95%;
    }

}
@media screen and (max-width: 1366px) {

    .blog-holder-scode {
        width: 100%;
        transform: none;
    }

    .blog-item-holder-scode {
        width: 29%;
        margin-right: 5%;
    }

    .blog-item-holder-scode h4 a {
        font-size: 32px;
    }
}
@media screen and (max-width: 1360px) {

    .blog-item-holder.has-post-thumbnail .entry-holder {
        width: 340px;
    }

}
@media screen and (max-width: 1270px) {

    .member-info {
        padding: 65px 80px;
    }

}
@media screen and (max-width: 1220px) {

    blockquote {
        font-size: 17px;
        line-height: 28px;
    }

}
@media screen and (max-width: 1024px) {

    .sm-clean li {
        margin-right: 8px;
    }
    .sm-clean a,
    .sm-clean a.highlighted,
    .sm-clean a:active,
    .sm-clean a:focus,
    .sm-clean a:hover {
        padding: 15px 8px;
    }

    .blog-holder,
    .footer-content {
        width: 100%;
    }

    .single .post-info-wrapper {
        width: 100%;
        text-align: center;
        float: none;
    }

    .single .sticky-spacer {
        width: 100% !important;
        height: auto !important;
        max-width: 100%;
    }

    .single .entry-info {
        position: relative !important;
        top: 0 !important;
    }
    .single .entry-info:after {
        display: none;
    }

    .single .sticky-spacer .entry-info > div {
        display: inline-block;
        margin: 10px 25px;
    }

    .single .entry-content {
        float: none;
        margin-left: auto;
        margin-right: auto;
        max-width: 95%;
    }

    .one_fourth,
    .one_half,
    .one_third,
    .three_fourth,
    .two_third {
        margin-right: 6%;
    }

    .one_half {
        width: 47%;
    }

    .one_third {
        width: calc(88% / 3);
    }

    .two_third {
        width: calc(194% / 3);
    }

    .one_fourth {
        width: calc(82% / 4);
    }

    .three_fourth {
        width: calc(318% / 4);
    }

    .section h3.entry-title {
        margin-bottom: 0 !important;
    }

    .sticky-spacer {
        height: auto !important;
        width: auto !important;
        position: relative !important;
    }

    .blog-item-holder-scode {
        width: 27%;
    }

    .latest-posts-background-featured-image-holder {
        height: 350px;
    }

    .pricing-wrapper {
        padding: 0 10%;
    }

    li.milestone {
        text-align: center;
    }

    li.milestone .milestone-info-left {
        margin: 0 auto;
        display: block;
        width: 100%;
        text-align: center;
    }

    li.milestone h5 {
        max-width: 100%;
    }

}
@media screen and (max-width: 1020px) {

    #header-main-menu {
        padding-bottom: 70px;
    }

    .menu-wrapper {
        width: 95%;
    }

    .main-menu ul {
        width: 12em;
    }

    .toggle-holder {
        display: block;
    }

    .sm-clean li {
        margin: 0 auto !important;
        border: 0;
    }

    .sm-clean a.has-submenu {
        display: inline-block;
    }

    .menu-holder {
        position: fixed;
        text-align: center;
        background-color: #000000;
        right: -100%;
        top: 77px;
        transition: right 0.3s ease;
        height: 100%;
        width: 300px;
        overflow: auto;
        max-width: 100%;
    }

    .header-holder {
        -webkit-transform: none;
    }

    .menu-holder.show {
        right: 0;
    }
    .sm-clean ul ul a,
    .sm-clean ul ul a:active,
    .sm-clean ul ul a:focus,
    .sm-clean ul ul a:hover {
        border: none;
    }

    .sm-clean a span.sub-arrow {
        background: transparent;
    }
    .sm-clean a,
    .sm-clean a.highlighted,
    .sm-clean a:active,
    .sm-clean a:focus,
    .sm-clean a:hover {
        padding: 10px 18px 10px 10px !important;
        margin-bottom: 5px;
    }

    h1.big-text {
        text-align: center;
        font-size: 60px;
    }

    .section-wrapper {
        padding-bottom: 70px;
    }

    .single article {
        padding-top: 35px;
    }

    .single h1.entry-title {
        font-size: 38px;
        line-height: 44px;
    }

    .single-post-header-content {
        margin-bottom: 35px;
    }

    .single-post .right-content-wrapper {
        float: none;
        width: 100%;
    }

    .tipper {
        display: none !important;
    }

    .blog-item-holder {
        width: 100%;
        padding: 5px 0;
        float: none;
    }

    .blog-item-wrapper {
        display: block;
    }
    .blog-item-holder:first-of-type {
        margin-top: 30px;
    }

    .blog-item-holder h2.entry-title {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
    }

    .blog-item-holder.has-post-thumbnail .excerpt {
        max-width: 700px;
        margin-bottom: 30px;
    }

    .blog-item-holder .post-thumbnail {
        float: none !important;
        text-align: center !important;
        margin-left: auto !important;
        margin-right: auto;
        margin-bottom: 30px !important;
        width: 95%;
        margin-top: 0;
    }

    .blog-item-holder.has-post-thumbnail .entry-holder {
        float: none !important;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
        width: 100%;
        padding: 0;
    }

    .blog-item-holder .cat-links ul {
        margin: 0;
    }

    .blog-item-holder a.button {
        margin-bottom: 30px;
    }

    .footer {
        padding-top: 30px;
    }

    .form-submit {
        padding-bottom: 20px;
    }

    .one_fourth,
    .one_half,
    .one_third,
    .three_fourth,
    .two_third {
        width: 100% !important;
        float: none;
        margin-right: auto !important;
        margin-left: auto !important;
        text-align: center;
    }

    .margin-0 img {
        width: 100% !important;
    }

    .grid-item,
    .grid-sizer {
        width: 100% !important;
    }

    .button-holder {
        text-align: center !important;
    }

    .service-holder {
        text-align: center;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
    }

    [data-jarallax-element] {
        transform: none !important;
    }

    .no-page-title .section-wrapper {
        padding: 65px 0 35px;
    }

    .text-slider-wrapper {
        padding: 10%;
    }

    .blog-item-holder-scode {
        display: block;
        text-align: center;
        width: 100%;
        margin-left: auto !important;
        margin-right: auto !important;
        max-width: 400px;
    }

    .medium-text {
        font-size: 40px;
        line-height: 105%;
        padding-top: 10px;
    }

    .portfolio-load-content-holder {
        max-width: 90%;
    }

    .close-icon {
        top: 120px;
        right: -10px;
        left: auto;
    }

    .pricing-table {
        margin-bottom: 100px;
    }

    .member {
        margin: 35px auto;
        max-width: 80%;
    }

    .member img {
        display: block;
        margin: 0 auto;
        width: 100% !important;
    }

    .member-info {
        display: block;
        margin: 0 auto !important;
        width: 80%;
        padding: 10%;
    }

    .member-social-holder {
        right: 0 !important;
        left: 0 !important;
        width: 100%;
        text-align: center;
        padding: 25px 0;
    }

    .skill-percent {
        display: block;
        width: 100%;
        font-size: 40px;
    }

    .skill-text {
        display: block;
        width: 100%;
    }

    .skill-text span {
        margin-bottom: 10px;
        display: block;
    }

    li.milestone {
        width: 100%;
        margin-bottom: 60px;
        display: block;
        max-width: 350px;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    li.milestone:last-child {
        margin-bottom: 0;
    }

    li.milestone > div {
        width: 100% !important;
        display: block;
        margin-left: auto !important;
        margin-right: auto !important;
        text-align: center !important;
    }

    p.milestone-num {
        font-size: 65px;
    }

    li.milestone h5 {
        max-width: 100%;
        margin-top: 20px;
    }

    .portfolio-item-wrapper {
        margin-top: 50px;
    }

    .single .nav-links {
        transform: none;
        margin-top: 50px;
        text-align: center;
    }

}
@media screen and (max-width: 767px) {

    .page-title-holder {
        height: 100px;
    }

    .section h3.entry-title {
        margin-top: 18px;
    }

    .service-holder {
        margin: 0 auto;
    }

    .section-wrapper {
        padding: 130px 0 30px;
    }
    .page-title-holder:after {
        border-width: 100px 60px 0 0;
    }

    .blog-item-holder h2.entry-title {
        font-size: 34px;
        line-height: 38px;
    }

    .footer-mail {
        font-size: 30px;
    }

    blockquote {
        max-width: 100%;
        margin-top: 25px;
    }
    blockquote:before {
        position: relative;
        left: 0;
        font-size: 180px;
        line-height: 37px;
        top: 0;
    }

    .blog-item-holder-scode .post-thumbnail {
        margin-top: 70px;
    }
    .blog-holder-scode > article:first-of-type .post-thumbnail {
        margin-top: 0;
    }

    .latest-posts-background-featured-image-holder {
        height: 300px;
    }

    .member {
        max-width: 100%;
    }

    .member-name {
        font-size: 35px;
    }

    .member-content {
        font-size: 16px;
        line-height: 170%;
        margin-bottom: 50px;
    }

    .member-postition {
        font-size: 13px;
    }

    .text-slider-wrapper {
        width: 80%;
    }

    .text-slider-wrapper .text-slide {
        font-size: 20px;
    }

    .text-slider-wrapper img.text-slide-img {
        display: block;
        margin-bottom: 10px;
    }

    .text-slider-wrapper .text-slide-name {
        display: block;
        margin-bottom: 5px;
    }

    .text-slider-wrapper .text-slide-position {
        display: block;
        font-size: 14px;
        line-height: 125%;
    }

}
@media screen and (max-width: 750px) {

    .blog-item-holder .entry-holder {
        max-width: 95%;
    }

}
@media screen and (max-width: 650px) {

    .blog-item-holder .cat-links,
    .blog-item-holder .entry-date.published,
    .single .entry-info .cat-links ul,
    .single .entry-info div {
        display: block;
    }
    .blog-item-holder .entry-date.published:after,
    .single .entry-info div:after {
        display: none;
    }

    h1.big-text {
        text-align: center;
        font-size: 40px;
    }

}
@media screen and (max-width: 400px) {

    .page-title-holder {
        width: 200px;
    }

    .footer-mail {
        font-size: 28px;
    }

}

.service-bodytext {
    height: 140px;
    text-align-last: center;
    width:208px;
    font-size:15px;

}

/* Grid images --------------------------------------------*/

.ImageGridcontainer {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 256px);
    margin: 0 auto;
    max-width: 90%;
    padding: 2rem 0;
}

/* Grid images --------------------------------------------*/

/* Grid items --------------------------------------------*/

.item-4 {
    /* grid-row: 2/5; */
    grid-row: 1/4;
    cursor: pointer;

}

.item-5 {
    grid-row: 2/6;
}

.item-1 {
    grid-row: 1/4;
    cursor: pointer;

}
.item-2 {
    grid-row: 1/4;
    cursor: pointer;

}
.item-3 {
    grid-row: 1/4;
    cursor: pointer;

}

.item-6 {
    grid-row: 2/4;
}

.item-7 {
    grid-row: 5/6;
}

.item-8 {
    grid-row: 4/6;
}

.item-9 {
    grid-row: 6/11;
}

.item-10 {
    grid-row: 6/7;
}

.item-11 {
    grid-row: 6/9;
}

.item-12 {
    grid-row: 7/12;
}

.item-13 {
    grid-row: 9/13;
}

.item-14 {
    grid-row: 11/13;
}

.imagegriditem {
    cursor: pointer;
}

.gridimg {
    border-radius: 1rem;
    height: 100%;
    width: 100%;
    object-fit: cover;
    top: -100%;
    position: relative;
}
.imagegridContent {
    border-radius: 1rem;
    height: 100%;
    width: 100%;
    position: relative;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -75%);
    z-index: 999;
}

.imagegridContent-hovered {
    border-radius: 1rem;
    height: 100%;
    width: 100%;
    position: relative;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -75%);
    z-index: 97;
    background-color: #001d4894;
}

.imagegridTextContent {
    position: relative;
    top: 35%;
}

.ImageHoverCarditem {
    text-align: center;
    cursor: pointer;

}

.imagegridTextContent-Header {
    font-size: 28px;
    line-height: 149%;
    font-weight: 900;
    word-break: break-word;
    text-align: center;
    color: #fff;
    letter-spacing: 2px;
}

.imagegridTextContent-Body {
    font-size: 14px;
    line-height: 149%;
    font-weight: 400;
    word-break: break-word;
    text-align: center;
    color: #fff;
    letter-spacing: 2px;
}

.button-centerFrame {
    position: relative;
    /* left: 50%; */
    text-align: center;
    /* top: 0%; */
    /* transform: translateY(-151%); */
}
.imagegrid-contentwrapper {

    height: 29em;
}
.headerButton {
    text-align: center;
    place-content: center;
    position: relative;
    /* top: -1em; */
    /* margin-top: -5em; */
}
